import { defineStore } from "pinia";

export const useConversationStore = defineStore("conversationStore", {

	state: () => ({

		$assistantApi: useNuxtApp().$assistantApi,

		threadStore: useThreadStore(),

		conversationForm: {
			message: ''
		},

		isTyping: false,

		messages: [],

		lastChunk : null,

		error : null,

	}),

	getters: {
		threadStarted: (state) => state.threadStore.activeThread !== null,

		lastMessage: (state) => state.messages[state.messages.length - 1]
	},

	actions: {

		async loadConversation() {
		
			await this.threadStore.loadActiveThread();

		},

		async sendMessage() {

			if (this.conversationForm.message === '') {
				return;
			}

			try {

				this.isTyping = true;

				this.error = null;

				this.messages.push({
					content: [{ text : { value : this.conversationForm.message }}],
					role: 'user'
				});

				this.lastChunk = this.conversationForm.message;

				const params = {
					threadId: (this.threadStarted) ? this.threadStore.activeThread.id : null,
					messageContent: this.conversationForm.message
				};

				const options = {
					headers: {
						'Content-Type': 'application/json'
					}
				};

				const response = await this.$assistantApi.post('/conversations', params, options)

				this.processStream(response);


			} catch (error) {
				console.error(error);

				this.error = error;

				this.isTyping = false;
			}
		},

		resetConversationForm() {
			this.conversationForm.message = '';
		},

		parseMessage(eventData) {
			
			console.log(eventData.event + '...')
			// console.log(eventData.data);

			if (eventData.event === 'thread.run.created') {
				this.threadStore.saveThreadId(eventData.data.thread_id);
			}

			if (eventData.event === 'thread.message.delta') {

				this.isTyping = false;

				this.handleMessageDelta(eventData)
				
			}

			if (eventData.event === 'app.using_tool') {
				this.messages.push({
					role: 'app',
					event : 'app.using_tool',
					content: eventData.data
				});
			}

			if (eventData.event === 'app.action_button') {
				console.log('creating action button...');
				

				this.messages.push({
					role: 'app',
					event : 'app.action_button',
					content: eventData.data
				});
			}

			if (eventData.event === 'thread.run.completed') {
				// console log cost
				const inputTokens = eventData.data.usage.prompt_tokens;
        const outputTokens = eventData.data.usage.completion_tokens;

				console.log("Usage...");
				console.log("Input tokens:", inputTokens);
				console.log("Output tokens:", outputTokens);
			}
		},

		handleMessageDelta(eventData) {
			
			this.lastChunk = eventData.data.delta.content[0].text.value;

			if (this.messages.length > 0 && this.messages[this.messages.length - 1].role !== 'assistant') {
				this.messages.push({
					role: 'assistant',
					content: [{ text : { value : eventData.data.delta.content[0].text.value }}],
				});
			} else {
				this.messages[this.messages.length - 1].content[0].text.value += eventData.data.delta.content[0].text.value;
			}
		},

		processStream(response) {

			const reader = response.body.getReader();
			const decoder = new TextDecoder();

			this.readChunk(reader, decoder);
		},

		readChunk(reader, decoder) {

			reader.read().then(({ done, value }) => {

				if (done) {
					console.log("Stream finished");
					return;
				}

				const chunk = decoder.decode(value, { stream: true });

				chunk.split("\n\n").forEach(eventString => {
					if (eventString.startsWith("data: ")) {

						const data = eventString.slice(6); // Remove 'data: ' prefix

						try {
							const eventData = JSON.parse(data);

							this.parseMessage(eventData);

						} catch (err) {
							console.error("Failed to parse JSON:", err);
						}
					}
				});

				this.readChunk(reader, decoder);

			}).catch(err => {
				console.error("Stream reading failed:", err);
			});
		},
	}
});