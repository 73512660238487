<template>
  <template v-if="$vuetify.display.mobile">
    <v-alert color="surface" density="compact" v-if="showAlert" closable>
      <small>{{ text }}</small>
      <small class="text-primary ml-2" @click="handleSubscribe">Assinar</small>
    </v-alert>
  </template>
  <template v-else>
    <v-alert color="surface" density="compact" v-if="showAlert" closable>
      <div class="d-flex align-center justify-center">
        <div>
          {{ text }}
        </div>
        <div>
          <app-btn to="/assinar" color="primary" class="text-capitalize ml-md-8" elevation="0" v-text="'Assine Já'" />
        </div>
      </div>
    </v-alert>
  </template>
</template>

<script setup>
const router = useRouter()

const subscriptionStore = useSubscriptionStore()

// computed
const showAlert = computed(() => {
  return subscriptionStore.isOnTrial && !subscriptionStore.isFreeAccount
})

const daysToEndTrial = computed(() => {
  return subscriptionStore.daysToEndTrial
})

const text = computed(() => {

  if (daysToEndTrial.value > 5) {
    return `Você tem ${daysToEndTrial.value} dias para testar o Apex Comércio!`
  }

  if (daysToEndTrial.value === 1) {
    return 'Seu período de teste termina amanhã!'
  }

  if (daysToEndTrial.value === 0) {
    return 'Seu período de teste termina hoje!'
  }

  if (daysToEndTrial.value < 0) {
    return 'Seu período de teste já terminou!'
  }

  return `Seu período de teste termina em ${daysToEndTrial.value} dias!`
})

const handleSubscribe = () => {
  router.push('/assinar')
}


</script>