<template>
  <template v-if="$vuetify.display.mobile">
    <v-alert :color="alertColor" density="compact" v-if="showAlert" closable>
      {{ alertText }}
      <small class="text-primary ml-2" @click="handleSubscribe">Ver</small>
    </v-alert>
  </template>
  <template v-else>
    <v-alert :color="alertColor" density="compact" v-if="showAlert" closable>
      <div class="d-flex align-center justify-center">
        <div>
          {{ alertText }}
        </div>
        <div>
          <app-btn to="/configuracoes/assinatura" color="primary" class="text-capitalize ml-md-8" elevation="0"
            v-text="'Ver'" />
        </div>
      </div>
    </v-alert>
  </template>
</template>

<script setup>

import { differenceInBusinessDays } from 'date-fns';

const router = useRouter()

const subscriptionStore = useSubscriptionStore()

const {
  hasUnpaidCharges,
  unpaidCharges,
  hasUnpaidInvoices,
  unpaidInvoices,
  hasPastDueInvoices,
  hasPastDueCharges,
  pastDueDays,
  isFreeAccount
} = storeToRefs(subscriptionStore)

const hasNearPendingCharge = computed(() => {
  if (hasUnpaidCharges.value) {

    let daysToExpire = differenceInBusinessDays(
      new Date(unpaidCharges.value[0].expire_at),
      new Date()
    )

    return daysToExpire < 1
  }
})

const hasNearPendingInvoice = computed(() => {
  if (hasUnpaidInvoices.value) {

    let daysToExpire = differenceInBusinessDays(new Date(unpaidInvoices.value[0].due_date), new Date())

    return daysToExpire < 1
  }
})

const alertText = computed(() => {
  if (hasPastDueInvoices.value || hasPastDueCharges.value) {
    return 'Atenção! Você tem cobranças vencidas'
  } else {
    return 'Você tem cobranças pendentes'

  }
})

const alertColor = computed(() => {
  if (hasPastDueInvoices.value || hasPastDueCharges.value) {
    return 'error'
  } else {
    return null
  }
})


const showAlert = computed(() => {

  if (isFreeAccount.value) {
    return false
  }

  if (hasUnpaidCharges.value && hasNearPendingCharge.value) {
    return true
  }

  if (hasUnpaidInvoices.value && hasNearPendingInvoice.value) {
    return true
  }

  return false
})

const handleSubscribe = () => {
  router.push('/configuracoes/assinatura')
}


</script>