<template>
  <v-navigation-drawer id="assistantDrawer" class="d-flex" width="800" location="right" v-model="isOpen" temporary
    :scrim="false">
    <template v-slot:prepend>
      <div class="pa-4 d-flex justify-space-between">
        <div class="d-flex flex-column ga-4">
          <div>
            <Icon name="hugeicons:magic-wand-01" class="mr-2" size="24" />
            Assistente com I.A.
          </div>
          <div>
            <AppBtn color="primary" size="small" variant="outlined" @click="handleNewThread()">Nova conversa</AppBtn>
          </div>

        </div>
        <div>
          <v-icon @click="isOpen = false" icon="mdi:mdi-close"></v-icon>
        </div>
      </div>
    </template>

    <v-divider></v-divider>

    <!-- chat section -->
    <div class="pa-4 d-flex flex-grow flex-column">

      <template v-if="!booted && !chatError">
        <div>
          <span class="text-medium-emphasis">Carregando...</span>
        </div>
      </template>
      <template v-if="hasMessages">
        <template v-for="(message, index) in messages">

          <template v-if="message.role == 'assistant'">
            <AssistantMessage class="mb-2" :message="message" />
          </template>

          <template v-if="message.role == 'user'">
            <UserMessage class="mb-2 align-self-end" :message="message" />
          </template>

          <template v-if="message.role == 'app'">

            <template v-if="message.event == 'app.using_tool'">
              <AppMessage class="mb-2" :message="message" />
            </template>

            <template v-if="message.event == 'app.action_button'">
              <ChatActionBtnMessage class="mb-2" :message="message" />
            </template>

          </template>

        </template>
      </template>
      <template v-else>
        <div class="w-100 mt-2 mt-md-10">
          <div class="text-center mb-4 ">
            <div>
              <VAvatar size="128">
                <v-img :src="'images/app/assistants/icon-512x512.png'" />
              </VAvatar>
            </div>
            <div>
              <h2 class="font-weight-medium">Gerente de Vendas</h2>
              <span class="text-medium-emphasis">Posso te ajudar com informações sobre suas vendas!</span>
            </div>
          </div>
          <div class="mt-2 d-flex ga-2 justify-center mx-0 mx-md-10 flex-wrap">
            <template v-for="message in modelMessages">
              <VChip color="primary" @click="sendModelMessage(message)">{{ message }}</VChip>
            </template>
          </div>
        </div>

      </template>
      <template v-if="isTyping">
        <div class="d-flex pa-2">
          <div class="mr-2">
            <VAvatar size="28">
              <v-img :src="'images/app/assistants/icon-512x512.png'" />
            </VAvatar>
          </div>
          <div>
            <span class="text-medium-emphasis">Pensando...</span>
          </div>
        </div>
      </template>
      <template v-if="error">
        <div class="pa-2 d-flex">
          <div class="mr-2">
            <Icon class="text-error" name="hugeicons:dead" size="24px" />
          </div>
          <div>
            <div>
              <span class="text-error">
                Falha no chat
              </span>
            </div>
            <div>
              <small class="text-medium-emphasis">{{ error }}</small>
            </div>
          </div>
        </div>
      </template>
      <template v-if="chatError">
        <div class="pa-2 d-flex">
          <div class="mr-2">
            <Icon class="text-error" name="hugeicons:dead" size="24px" />
          </div>
          <div>
            <div>
              <span class="text-error">
                Falha no chat
              </span>
            </div>
            <div>
              <small class="text-medium-emphasis">{{ chatError }}</small>
            </div>
          </div>
        </div>
      </template>
    </div>

    <template v-slot:append>
      <v-divider></v-divider>
      <div class="pa-4 d-flex align-center ga-4">
        <AppTextField v-model="conversationForm.message" @keydown.enter="sendMessage" placeholder="Digite uma mensagem"
          :disabled="isTyping">
          <template v-slot:append-inner>
            <VBtn icon variant="tonal" color="primary" density="compact">
              <Icon name="hugeicons:arrow-up-02" size="24" @click="sendMessage" />
            </VBtn>
          </template>
        </AppTextField>
      </div>
    </template>

  </v-navigation-drawer>
</template>

<script setup>

const assistantStore = useAssistantStore();

const threadStore = useThreadStore();

const { activeThread } = storeToRefs(threadStore);

const conversationStore = useConversationStore();

const { conversationForm, messages, isTyping, thread, lastChunk, error } = storeToRefs(conversationStore);

const { isOpen } = storeToRefs(assistantStore);

const booted = ref(false);

const chatError = ref(null);

const modelMessages = ref([
  'Como estão as vendas esse mês? 🤑',
  'Quai foi o melhor mês do ano? 📆',
  'Quais feriados tem esse mês 🏝️',
  'Qual categoria está vendendo mais? 📊',
  'Faça uma projeção para esse mês 🔮',
  'Como está o lucro das vendas este mês? 💰',
])

onMounted(async () => {

  await nextTick();

  scrollToBottom()
})

watch(() => isOpen.value, async () => {
  if (!booted.value && isOpen.value) {
    await boot();
  }
});

watch(() => lastChunk.value, async () => {
  await nextTick();
  scrollToBottom();
});

const hasMessages = computed(() => messages.value.length > 0);

const boot = async () => {
  try {

    await loadConversation();

    booted.value = true;

    await nextTick();

    scrollToBottom()

  } catch (e) {

    chatError.value = e.message;
    console.error(e);
  }
}

const loadConversation = async () => {

  await conversationStore.loadConversation();

}

const sendMessage = async () => {

  await conversationStore.sendMessage();

  conversationStore.resetConversationForm()

  await nextTick();

  scrollToBottom()

}

const sendModelMessage = async (message) => {

  conversationStore.conversationForm.message = message;

  await sendMessage();

}

const handleNewThread = async () => {

  await threadStore.newThread();

}

const scrollToBottom = () => {

  const chat = document.getElementById('assistantDrawer').querySelector('.v-navigation-drawer__content');

  chat.scrollTop = chat.scrollHeight;
}

</script>

<style lang="scss" scoped></style>