<template>
  <div>
    <v-alert density="compact" v-if="$pwa.needRefresh">
      <div class="d-flex align-center justify-center">
        <div>
          Tem uma nova versão disponível, clique no botão e atualize agora!
        </div>
        <div>
          <v-btn @click.stop="refresh()" prepend-icon="mdi:mdi-refresh" color="primary" class="text-capitalize ml-md-8"
            elevation="0">
            Atualizar
          </v-btn>
        </div>
      </div>
    </v-alert>

    <!-- <template v-if="needRefresh">
      <v-sheet :color="drawerColor" class="pa-2">
        <b>Nova Versão Disponível</b>
        <div class="pt-2">
          <v-btn
            @click.stop="refresh()"
            block
            color="primary"
            prepend-icon="mdi:mdi-refresh"
          >
            Atualizar
          </v-btn>
        </div>
        <v-divider />
      </v-sheet>
    </template> -->

    <AppDialog v-model="dialog" persistent width="400">
      <v-card>
        <v-card-title class="pt-15 pb-8 d-flex justify-center">
          <v-icon color="primary" icon="mdi:mdi-download" size="60px" />
        </v-card-title>
        <v-card-text class="d-flex flex-column align-center px-4 py-4">
          <h2 class="pb-4 text-center">Existe uma nova versão disponível!</h2>
          <span class="text-center">
            Clique no botão abaixo e mantenha o seu sistema sempre atualizado, é
            rápido
          </span>
        </v-card-text>

        <v-card-actions class="d-flex justify-center pa-6">
          <v-row>
            <v-col cols="12" class="pb-0">
              <AppBtn block color="primary" @click="refresh()">
                Atualizar agora!
              </AppBtn>
            </v-col>
            <v-col cols="12" class="pb-0">
              <AppBtn variant="text" block text color="primary" @click="dialog = false">
                Atualizar depois
              </AppBtn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </AppDialog>
  </div>
</template>

<script setup>

const { $pwa } = useNuxtApp();

const dialog = ref(false);

const refresh = () => {
  $pwa.updateServiceWorker();
};

watch(
  () => $pwa.needRefresh,
  (value) => {
    dialog.value = value;
  }
);
</script>
