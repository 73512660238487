<template>
  <v-img :src="src"  />
</template>

<script setup>

import { useTheme } from 'vuetify'

const theme = useTheme();

const isDark = computed(() => theme.global.current.value.dark)

const isBirthday = computed(() => {
  let today = new Date();

  return today.getMonth() === 3 && today.getDate() === 27
})

const src = computed(() => {

  if (isBirthday.value) {
    
    if (isDark.value) {
      return '/images/logo/logo_dark_birthday.png'
    }  else {
      return '/images/logo/logo_light_birthday.png'
    }

  } else {
    
    if (isDark.value) {
      return '/images/logo/logo_dark.png'
    }  else {
      return '/images/logo/logo_light.png'
    }
  }
})

</script>

