import { defineStore } from "pinia";

export const useThreadStore = defineStore("threadStore", {

	state: () => ({
    
    $assistantApi : useNuxtApp().$assistantApi,

    authStore : useAuthStore(),

    conversationStore : useConversationStore(),

    activeThread : null,

	}),

	actions: {
    
    newThread () {
      
      this.activeThread = null;

      this.removeThreadId()

      this.conversationStore.messages = [];

    },

    async loadActiveThread() {

      const threadId = await this.loadThreadId();

      if (threadId) {
        
        const response = await this.$assistantApi.get('/threads/' + threadId);
        
        if (response.ok) {
          
          const data = await response.json();
          
          const { thread, threadMessages } = data;

          this.activeThread = thread;

          this.conversationStore.messages = threadMessages.reverse();
          
        }

      }
    },

    createThread() {
      
      const response = this.$assistantApi.post('/threads');

      return response;
    },

    saveThreadId(threadId) {

      this.activeThread = { id : threadId };

      const path = 'assistant/threadId/' + this.authStore.user.id;

      localStorage.setItem(path, threadId);

    },

    async loadThreadId() {
      
      const path = 'assistant/threadId/' + this.authStore.user.id;

      return await localStorage.getItem(path);
    },

    async removeThreadId() {
      
      const path = 'assistant/threadId/' + this.authStore.user.id;

      return await localStorage.removeItem(path);
    }

	}
});