<template>
  <div>
    <AppNavigationDrawer ref="appNavigationDrawer" />
    <AssistantDrawer v-if="features.has('ai_assistant')" />
    <template v-if="$vuetify.display.mobile">
      <v-app-bar :elevation="0">
        <!-- <v-app-bar-nav-icon @click="handleToggleDrawer()"></v-app-bar-nav-icon> -->
        
        <v-btn class="ml-2" density="compact" icon size="x-large" @click="handleToggleDrawer()">
          <AppIcon name="hugeicons:menu-02" size="28"/>
        </v-btn>

        <v-toolbar-title>
          {{ $route.meta.title }}
        </v-toolbar-title>

        <template v-slot:append>
          <div id="appBarAppend"></div>
        </template>
      </v-app-bar>
    </template>
    <template v-else>
      <!-- prevent teleport error -->
      <div class="d-none" id="appBarPrepend"></div>
      <div class="d-none" id="appBarAppend"></div>
    </template>

    <v-main>
      <AppAlertNewVersion />
      <FreeTrialAlert />
      <PendingChargesAlert />
      <BoletoExpiredAlert />
      <slot />
    </v-main>

    <template v-if="$vuetify.display.mobile">
      <div id="appBottomBar"></div>
    </template>
  </div>
</template>

<script setup>
import { useDisplay, useTheme } from "vuetify";

const router = useRouter();

const acl = useAcl();

const features = useFeatures();

const authStore = useAuthStore();

const theme = useTheme();

const appStore = useAppStore();

const { initialAnimation } = storeToRefs(appStore);

const appNavigationDrawer = ref(null);

const user = computed(() => authStore.user);

const isSystemAdmin = computed(() => acl.isSystemAdmin());

const handleToggleDrawer = () => {
  appNavigationDrawer.value.drawer = !appNavigationDrawer.value.drawer;
};

const handleLogout = () => {
  authStore.logout();

  router.push("/login");
};

onMounted(() => {
  const themeName = localStorage.getItem("app-theme");

  if (themeName) {
    theme.global.name.value = themeName;
  }

  if (process.env.NODE_ENV === "production") {
    useBugsnag().setUser(user.value.id, user.value.email, user.value.name);
  }

  // wait 1 seccond set initialAnimation = true for 3 seconds, and then set it to false
  setTimeout(() => {
    appStore.initialAnimation = true;

    setTimeout(() => {
      appStore.initialAnimation = false;
    }, 2800);
  }, 800);
});
</script>

