export default
  [
    { type: "route", path: '/', displayName: 'Inicio', permission: 'home', icon: "hugeicons:home-06" },
    { type: "route", path: '/clientes', displayName: 'Clientes', icon: "hugeicons:user-multiple" },
    { type: "route", path: '/produtos', displayName: 'Produtos', icon: "hugeicons:package" },
    { type: "route", path: '/vendas', displayName: "Vendas", icon: "hugeicons:shopping-cart-01" },
    { type: "route", path: '/nfe', displayName: "Notas fiscais", icon: "hugeicons:document-attachment", feature: 'nfe' },
    { type: "route", path: '/ordem-servico', displayName: "Ordem de Serviço", icon: "hugeicons:wrench-01", feature: 'serviceOrder' },
    { type: "route", path: '/caixa', displayName: "Caixa", permission: 'viewDrawer', icon: "hugeicons:dollar-01" },
    { type: "route", path: '/contas-a-pagar', displayName: "Contas a pagar", permission: 'viewBillPayable', icon: "hugeicons:file-dollar", feature: 'billPayable' },
    { type: "route", path: '/estatisticas', displayName: "Estatísticas", permission: 'companyStats', icon: "hugeicons:analytics-up" },
  ]